import {createStore} from 'vuex'

export default createStore({
    state() {
        return {
            pageTitle: "Home",
            clickedField: null,
            forceClose: false,
            showFastApply: false,
            activePersons: null,
            activeOffers: null,
            activeStandorte: null,
            meta: {},
        }
    },
    getters: {},
    mutations: {
        setPageTitle(state, title) {
            state.pageTitle = title;
            document.title = title + " | MEDAU Therapy Services";
        },
        setClickedField(state, field) {
            state.clickedField = field;
        },
        forceClose(state) {
            state.forceClose = !state.forceClose;
        },
        showFastApply(state, dir) {
            state.showFastApply = dir;
        },
        setActivePersons(state, activePersons) {
            state.activePersons = activePersons;
        },
        setActiveOffers(state, activeOffers) {
            state.activeOffers = activeOffers;
        },
        setActiveStandorte(state, activeStandorte) {
            state.activeStandorte = activeStandorte;
        },
        setMetaObject(state, meta) {
            state.meta = meta;
        },
    },
    actions: {},
    modules: {}
})
