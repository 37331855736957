<template>
    <div class="spinner">
        <div class="spinner-inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {}
</script>