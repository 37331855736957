<template>
    <div class="headerbar headerbar-fields">
        <div class="headerbar-header">
            <div class="headerbar-color headerbar-color-1" @click="$emit('closeField')">
                <img :src="getImage('arrowLeft.svg')" class="back-arrow" alt="Zurück">
            </div>
            <div :class="'headerbar-color headerbar-color-2 '+($store.state.clickedField==='physio'?'active':'')">
                <div class="headerbar-field"><span>Medau</span>physio</div>
            </div>
            <div :class="'headerbar-color headerbar-color-3 '+($store.state.clickedField==='logo'?'active':'')">
                <div class="headerbar-field"><span>Medau</span>logo</div>
            </div>
            <div :class="'headerbar-color headerbar-color-4 '+($store.state.clickedField==='ergo'?'active':'')">
                <div class="headerbar-field"><span>Medau</span>ergo</div>
            </div>
            <div :class="'headerbar-color headerbar-color-5 '+($store.state.clickedField==='fit'?'active':'')">
                <div class="headerbar-field"><span>Medau</span>fit</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>