import {createRouter, createWebHistory} from 'vue-router';

function createRoutes(ignored_app) {
    const routes = [
        {
            path: '/',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/home.vue'),
                header:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/static/MedauHeader.vue'),
            }
        },
        {
            path: '/bereiche/',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/home.vue'),
                header:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/static/MedauHeader.vue'),
            }
        },
        {
            path: '/bereiche/:field',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/home.vue'),
                header:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/static/MedauHeader.vue'),
            }
        },
        {
            path: '/seiten/',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/notFound.vue'),
                header:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/static/StaticHeader.vue'),
            }
        },
        {
            path: '/seiten/:page',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/page.vue'),
                header:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/static/StaticHeader.vue'),
            }
        },
        {
            path: '/karriere/stellenangebote',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/stellenangebote.vue'),
                header:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/static/CareerHeader.vue'),
            }
        },
        {
            path: '/karriere/stellenangebote/:position',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/stellenangebot.vue'),
                header:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/static/CareerHeaderStatic.vue'),
            }
        },
    ];

    const route404 = {
        path: '/:catchAll(.*)',
        components: {
            default: () => import(/* webpackChunkName: "notFound" */ '../../components/views/notFound.vue'),
            header:
                () => import(/* webpackChunkName: "dashboard" */ '../../components/static/StaticHeader.vue'),
        }
    };
    // noinspection JSCheckFunctionSignatures
    routes.push(route404);

    return routes;
}

export default (app) => {
    return createRouter({
        history: createWebHistory(process.env.BASE_URL),
        scrollBehavior: function (to, ignored_from, ignored_savedPosition) {
            if (to.hash && to.hash !== "#") {
                return new Promise((resolve, ignored_reject) => {
                    let execs = 0;
                    let int = setInterval(() => {
                        if (document.querySelector(to.hash) !== null) {
                            clearInterval(int);
                            resolve({el: to.hash, behavior: 'smooth', top: 50});
                        } else {
                            execs++;
                            if (execs > 20) {
                                clearInterval(int);
                            }
                        }
                    }, 500);
                });
            } else {
                return {x: 0, y: 0}
            }
        },
        routes: createRoutes(app),
    })
}
