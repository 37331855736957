// External modules
import {createApp} from 'vue';
import store from './modules/store/index';
import cookies from 'vue-cookies';

// App-file
import App from './App.vue';

// Mixins
import functionsMixin from './modules/mixin/functions';
import configMixin from './modules/mixin/config';

// Router
import createRouter from './modules/router/index';

// CSS
import './assets/css/style_header.css';
import './assets/css/style_page.css';
import './assets/css/ptsans/css.css';
import './assets/css/sansation/css.css';


// Create app and configure router, mixins, plugins, etc.
const app = createApp(App).use(store);

const router = createRouter(app);
app.use(router);

app.use(cookies)
    .mixin(configMixin)
    .mixin(functionsMixin);
router.isReady().then(() => {
    app.mount('#app');
});