<template>
  <SmallHeaderBarFields @close-field="closeFieldHeader()" />
  <div :class="'header-wrapper '+(headerClicked?'clicked':'')" ref="header" v-if="fieldData">
    <template v-for="(value,key) in ['home','physio','logo','ergo','fit']">
      <div :class="'header-item header-item-'+key+' '+getCurrentlyOpen(value)+' '+getCurrentlyClicked(value)"
           :data-name="value"
           :ref="'field_'+value"
           @touchstart="(event) => clickElementTouch($refs['field_'+value][0], event)"
           @click="(event) => clickElementTouch($refs['field_'+value][0], event)"
           @mousemove="() => hoverElement($refs['field_'+value][0])"
      >
        <template v-if="value==='home'">
          <div class="header-item-inner header-item-inner-home-desktop">
            <img :src="getImage('logo_new.svg')" class="header-title-image hti-home" alt="">
            <div class="header-title">
              <span class="medau">Medau</span>therapie
            </div>
            <div class="upper-content"></div>
            <div class="middle-content" v-html="blueCnt" v-if="blueCnt!==null&&blueCnt!==false" />
            <div class="middle-content" v-if="blueCnt===null">
              <LoadingAnimation />
            </div>
            <div class="middle-content" v-if="blueCnt===false">
              Fehler beim Laden! Bitte aktualisieren Sie die Seite in einem Augenblick.
            </div>
            <div class="lower-content"></div>
          </div>
          <div class="header-item-inner header-item-inner-home-mobile">
            <div class="upper-content">
              <img :src="getImage('logo.svg')" alt="">
            </div>
            <div class="middle-content" v-html="blueCnt" v-if="blueCnt!==null&&blueCnt!==false" />
            <div class="middle-content" v-if="blueCnt===null">
              <LoadingAnimation />
            </div>
            <div class="middle-content" v-if="blueCnt===false">
              Fehler beim Laden! Bitte aktualisieren Sie die Seite in einem Augenblick.
            </div>
            <div class="lower-content"></div>
          </div>
        </template>
        <template v-else>
          <div class="header-item-inner">
            <div class="header-back"><img :src="getImage('arrowLeft.svg')" alt=""></div>
            <img :src="getImage('abt_'+value+'.svg')" class="header-title-image" alt="">
            <div class="header-title">
              {{ value }}
            </div>
            <div class="header-subtitle">
              <img :src="getImage('medau_'+value+'.svg')" alt="">
              <div v-html="fieldData[value]?.Kurzbeschreibung" />
              <button class="open-button">Mehr erfahren</button>
              <button class="close-button">Schließen</button>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
  <template v-else>
    <LoadingAnimation />
  </template>
</template>

<script>
import LoadingAnimation from "@/components/elements/LoadingAnimation.vue";
import SmallHeaderBarFields from "@/components/static/SmallHeaderBarFields.vue";

export default {
  data() {
    return {
      clickActive: false,
      clicked: null,
      opened: null,
      fieldData: null,
      selectedStandort: null,
      blueCnt: null,
    }
  },
  emits: ['closeField'],
  components: {LoadingAnimation, SmallHeaderBarFields},
  computed: {
    headerClicked() {
      return this.clicked !== null;
    },
    forceClose() {
      return this.$store.state.forceClose;
    }
  },
  watch: {
    clicked(val, ignored_oldVal) {
      this.$store.commit("setClickedField", val);
      if(val !== null) {
        this.$store.commit("setPageTitle", val);
        if(this.selectedStandort === null) {
          this.$router.push('/bereiche/' + val);
        } else {
          this.$router.push('/bereiche/' + val + '?standort=' + this.selectedStandort + '#standorte');
          this.selectedStandort = null;
        }
        document.body.classList.add("clicked");
      } else {
        this.$store.commit("setPageTitle", "Home");
        document.body.classList.remove("clicked");
        this.$router.push('/bereiche/');
      }
    },
    forceClose(ignored_val, ignored_oldVal) {
      this.closeItem();
    },
    $route(ignored_to, ignored_from) {
      const field = this.$route.params.field;
      if(field) {
        this.clicked = field;
      }
      if(this.$route.query.standort) {
        this.selectedStandort = this.$route.query.standort;
      }
    }
  },
  methods: {
    clickElementTouch(el, event) {
      event.preventDefault();

      if(el.classList.contains("open") || el.classList.contains("clicked")) {
        this.clickElement(el);
      } else {
        this.hoverElement(el);
      }
    },
    hoverElement(hovEl) {
      if(this.clickActive) {
        return;
      }
      if(this.headerClicked) {
        if(hovEl.dataset.name === this.clicked) {
          return;
        } else {
          scroll(0, 0);
          this.clicked = null;
        }
      }
      this.opened = hovEl.dataset.name;
    },
    clickElement(clEl) {
      if(clEl.classList.contains("header-item-0")) {
        return;
      }
      if(clEl.dataset.name === this.clicked) {
        scroll(0, 0);
        this.opened = this.clicked;
        this.clicked = null;
        return;
      }
      this.clickActive = true;
      setTimeout(() => {
        this.clickActive = false;
      }, 1500);
      this.opened = null;
      this.clicked = clEl.dataset.name;
    },
    closeItem() {
      scroll(0, 0);
      this.clicked = null;
    },
    getCurrentlyOpen(field) {
      if(field === this.opened || (this.opened === null && field === "home")) {
        return 'open'
      }
      return '';
    },
    getCurrentlyClicked(field) {
      if(field === this.clicked) {
        return 'clicked'
      }
      return '';
    },
    getMainHeaderContents() {
      this.apiRequest("/fields")
          .then(cnt => {
            let res = {};
            for(const el of cnt.data) {
              res[el.attributes.Titel] = el.attributes;
            }
            this.fieldData = res;
          })
          .catch(() => this.fieldData = false);
      this.apiRequest("/blue-header")
          .then(cnt => {
            const text = cnt?.data?.attributes?.Inhalt || '';
            this.blueCnt = text.replace(/medau/ig, '<span class="medau">Medau</span>');
          })
          .catch(() => this.blueCnt = false);

    },
    closeFieldHeader() {
      scroll(0, 0);
      this.$router.push("/");
      if(this.$store.state.clickedField !== null) {
        this.$store.commit('forceClose');
      }
    }
  },
  mounted() {
    document.body.classList.remove("clicked");
    scroll(0, 0);
    this.getMainHeaderContents();
    const field = this.$route.params.field;
    if(field) {
      this.clicked = field;
    }
    if(this.$route.query.standort) {
      this.selectedStandort = this.$route.query.standort;
    }
  },
}
</script>
