<template>
    <meta v-for="(value,key) in $store.state.meta" :property="key" :name="key" :content="value"/>
    <span id="top-of-page"/>
    <component is="style" v-if="$store.state.clickedField==='physio'">
        :root {
        --current:var(--green);
        --current-background: var(--green-background);
        }
    </component>
    <component is="style" v-if="$store.state.clickedField==='logo'">
        :root {
        --current:var(--light-blue);
        --current-background: var(--light-blue-background);
        }
    </component>
    <component is="style" v-if="$store.state.clickedField==='ergo'">
        :root {
        --current:var(--lila);
        --current-background: var(--lila-background);
        }
    </component>
    <component is="style" v-if="$store.state.clickedField==='fit'">
        :root {
        --current:var(--pink);
        --current-background: var(--pink-background);
        }
    </component>
    <div class="legal-wrapper">
        <router-link to="/seiten/impressum">Impressum</router-link>
        |
        <router-link to="/seiten/datenschutz">Datenschutz</router-link>
    </div>
    <MainMenu/>
    <router-view name="header" @close-field="closeField()"/>
    <router-view/>
</template>

<script>
import MedauHeader from "@/components/static/MedauHeader.vue";
import SmallHeaderBar from "@/components/static/SmallHeaderBarFields.vue";
import MainMenu from "@/components/static/MainMenu.vue";

export default {
    components: {
        MainMenu,
        SmallHeaderBar,
        MedauHeader,
    },
    computed: {},
    mounted() {
        document.addEventListener("scroll", (ignored_event) => {
            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
            let show = scrollPosition + 30 > window.innerHeight / 2;
            if (!document.getElementsByClassName("menu")[0].classList.contains("open")) {
                document.getElementsByClassName("headerbar-fields")
                    ?.[0]?.classList?.toggle("open", show);
            }
            let showPage = scrollPosition > 100;
            if (!document.getElementsByClassName("menu")[0].classList.contains("open")) {
                document.getElementsByClassName("headerbar-page")
                    ?.[0]?.classList?.toggle("open", showPage);
            }
        });
    }
}
</script>
