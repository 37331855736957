export default {
    apiBaseUrl: 'https://api.medau-therapie.de/api',
    imageBaseUrl: 'https://api.medau-therapie.de',
    apiKey: '60fa03f6d007afbbec3d0464f39dc3354d9a6a7ab484bb4dc3dfe9829d6b5129ce8698d9bf905a7aed98c516d22974e9545b44b' +
        '83ff617bb85d1240137e2385dbd9f97a91a0856f541969788aa36b9e59b34e45fdd6bd9fa3be06403c14c86777f273a26a742ebb4f5' +
        'e200ef7451b188524813e9f70ab11cf1608b20a2fa07a4',
    // apiBaseUrl: 'http://127.0.0.1:1337/api',
    // imageBaseUrl: 'http://127.0.0.1:1337',
    // apiKey: '4a5b319493b9f956e1f3885614843f619f12d6ec63452388f8ff5cd97c285b88a34e9882520b44d719f942377bd16b0d6fdc3a' +
    //     '000a7203f504f414e5c0410428e058ce0df1e9b822a25c824da28c1f088f8671b2d1f55ceca59904adb3ede866e797ff2650d644df' +
    //     '168b3c719f6bf8432792f7c55dfd170dc4542f6e411a826e',
}