// noinspection JSUnusedGlobalSymbols // All functions are used!

export default {
    methods: {
        apiRequest: async function (endpoint, method = 'GET', data = null, raw = false) {
            const allowedMethods = ['POST', 'GET', /*'PATCH', 'PUT', 'DELETE'*/];
            method = method.toUpperCase();
            if (!allowedMethods.includes(method)) {
                console.error("Forbidden method used!")
            }

            // noinspection JSUnresolvedVariable
            let url = this.config.apiBaseUrl + endpoint;
            const init = {
                method: method,
                cache: 'no-cache',
                headers: {
                    Authorization: 'Bearer ' + this.config.apiKey,
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
            }

            if (method !== "GET" && method !== "DELETE") {
                init.body = JSON.stringify(data);
                init.headers['Content-Type'] = 'application/json';
            }

            const response = await fetch(url, init);
            const ret = {rawResponse: response, status: response.status, success: false};

            if (response.status === 404) {
                return ret;
            }

            if (response.status === 201 || response.status === 204) {
                ret.success = true;
                return ret;
            }

            if (raw) {
                return response;
            }


            let cnt;
            try {
                cnt = await response.json();
            } catch (err) {
                console.error(err);
                return ret;
            }
            ret.cnt = cnt;

            if (response.status === 403 || response.status === 401) {
                alert("API returned 403");
                return ret;
            }

            if (response.status === 200) {
                if (typeof cnt === "object") {
                    cnt.success = true;
                }
                return cnt;
            }

            return ret;
        },
        getIcon(icon) {
            return this.getSVG("icons/" + icon);
        },
        getSVG(image) {
            return this.getImage("svg/" + image + ".svg");
        },
        getImage(image) {
            try {
                return require('@/assets/img/' + image);
            } catch (e) {
                console.error("Image " + image + " not found!");
            }
        },
        handleSEOObject(seoObject = null) {
            if (!seoObject || !(typeof seoObject === 'object') || Array.isArray(seoObject)) {
                seoObject = {};
            }

            const finalSEOObject = {};

            if ("metaDescription" in seoObject && seoObject.metaDescription) {
                finalSEOObject["description"] = seoObject.metaDescription;
            }

            if ("keywords" in seoObject && seoObject.keywords) {
                finalSEOObject["keywords"] = ("" + seoObject.keywords).replaceAll("\n", ",");
            }

            if ("metaSocial" in seoObject && seoObject.metaSocial) {
                const metaSocial = seoObject.metaSocial;
                for (const entry of metaSocial) {
                    let prefix = null;
                    if (entry.socialNetwork === "Facebook") {
                        prefix = "og:";
                    } else if (entry.socialNetwork === "Twitter") {
                        prefix = "twitter:";
                    }
                    if (!prefix) {
                        continue;
                    }
                    if (entry.title) {
                        finalSEOObject[prefix + "title"] = entry.title;
                    }
                    if (entry.description) {
                        finalSEOObject[prefix + "description"] = entry.description;
                    }
                    if (entry.image) {
                        const url = entry.image?.data?.attributes?.url;
                        if (url) {
                            finalSEOObject[prefix + "image"] = this.config.imageBaseUrl + url;
                        }
                    }
                }
            }

            this.$store.commit("setMetaObject", finalSEOObject);
        }
    },
}