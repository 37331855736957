<template>
    <div :class="'menu-link '+(menuOpen?'open':'')">
        <div class="bar-wrapper" @click="toggleMenu()">
            <div class="bar1 bars"></div>
            <div class="bar2 bars"></div>
            <div class="bar3 bars"></div>
        </div>
    </div>
    <div :class="'menu '+(menuOpen?'open':'')">
        <div class="menu-content">
            <template v-if="contents!==null&&contents!==false">
                <div :class="'menu-top-link menu-top-link-'+content.attributes.EindeutigeBezeichnung"
                     v-for="content in contents">
                    <div class="menu-top-link-title" @click="toggleMobileMenu($event.currentTarget)"
                         v-html="medauWrap(content.attributes.Titel)"/>
                    <div class="menu-submenu">
                        <template v-if="['physio','logo','ergo','fit']
                        .includes(content.attributes.EindeutigeBezeichnung)">
                            <template v-if="fields[content.attributes.EindeutigeBezeichnung]!==null
                            &&fields[content.attributes.EindeutigeBezeichnung]!==false">
                                <div v-for="standort in fields[content.attributes.EindeutigeBezeichnung]">
                                    <router-link class="menu-submenu-link" :to="'/bereiche/'+
                                    content.attributes.EindeutigeBezeichnung+'?standort='+standort.id+'#standorte'">
                                        {{ standort.attributes.Ort }}
                                    </router-link>
                                </div>
                            </template>
                            <div v-else></div>
                        </template>
                        <div v-for="page in content.attributes.Links" v-else>
                            <template v-if="page.__component==='menu.interner-link'">
                                <template v-if="['stellenangebote']
                                .includes(page.Seite.data.attributes.EindeutigeBezeichnung)">
                                    <router-link class="menu-submenu-link"
                                                 :to="'/karriere/'+page.Seite.data.attributes.EindeutigeBezeichnung"
                                                 v-html="medauWrap(page.Titel)"/>
                                </template>
                                <template v-else>
                                    <router-link class="menu-submenu-link"
                                                 :to="'/seiten/'+page.Seite.data.attributes.EindeutigeBezeichnung"
                                                 v-html="medauWrap(page.Titel)"/>
                                </template>
                            </template>
                            <template v-if="page.__component==='menu.externer-link'">
                                <a class="menu-submenu-link" target="_blank" :href="page.URL"
                                   v-html="medauWrap(page.Titel)"/>
                            </template>
                            <template v-if="page.__component==='menu.anker-link'">
                                <router-link class="menu-submenu-link"
                                             :to="'/seiten/'+page.Seite.data.attributes.EindeutigeBezeichnung+
                                             '#'+page.Anker"
                                             v-html="medauWrap(page.Titel)"/>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <div v-else></div>
            <p>
                <!--suppress HtmlUnknownTarget -->
                <router-link to="/seiten/impressum">Impressum</router-link>
            </p>
            <p>
                <!--suppress HtmlUnknownTarget -->
                <router-link to="/seiten/datenschutz">Datenschutz</router-link>
            </p>
            <p>
                <a href="//facebook.com" target="_blank">
                    <img :src="getImage('facebook.png')" class="footer-social-facebook footer-social-image"
                         alt="Zu Facebook">
                </a>
                <a href="//instagram.com" target="_blank">
                    <img :src="getImage('instagram.png')" class="footer-social-instagram footer-social-image"
                         alt="Zu Instagram">
                </a>
            </p>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            menuOpen: false,
            autoTimeoutMenu: null,
            contents: null,
            fields: {
                physio: null,
                logo: null,
                ergo: null,
                fit: null,
            },
        }
    },
    watch: {
        $route(ignored_to, ignored_from) {
            this.toggleMenu(true, false);
        }
    },
    methods: {
        toggleMenu(force = false, state = true) {
            if (!force) {
                this.menuOpen = !this.menuOpen;
            } else {
                this.menuOpen = state === true;
            }
            if (this.menuOpen) {
                document.getElementsByClassName("headerbar-fields")?.[0]?.classList?.remove("open");
                document.getElementsByClassName("headerbar-fields")?.[0]?.classList?.add("menuopen");
                document.getElementsByClassName("headerbar-page")?.[0]?.classList?.remove("open");
            } else {
                let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                let show = scrollPosition + 30 > window.innerHeight / 2;
                if (show) {
                    document.getElementsByClassName("headerbar-fields")?.[0]?.classList?.add("open");
                }
                let showPage = scrollPosition > 100;
                if (showPage) {
                    document.getElementsByClassName("headerbar-page")?.[0]?.classList?.add("open");
                }
                document.getElementsByClassName("headerbar-fields")?.[0]?.classList?.remove("menuopen");
            }
        },
        toggleMobileMenu(element) {
            element = element.parentElement;
            if (this.autoTimeoutMenu !== null) {
                clearTimeout(this.autoTimeoutMenu);
                this.autoTimeoutMenu = null;
            }
            const container = element.querySelector(".menu-submenu");
            container.style.height = "auto";
            const height = container.clientHeight + "px";
            if (container.classList.contains("open")) {
                container.classList.remove("open");
                element.classList.remove("open");
                container.style.height = height;
                setTimeout(() => {
                    container.style.height = '0px';
                }, 0);
            } else {
                container.classList.add("open");
                element.classList.add("open");
                container.style.height = "0px"
                setTimeout(() => {
                    container.style.height = height;
                }, 0);
                this.autoTimeoutMenu = setTimeout(() => {
                    container.style.height = 'auto';
                }, 500);
            }
        },
        getMenuContent() {
            this.apiRequest("/menuepunkte?sort[]=Sortierung" +
                "&populate[Links][populate][Seite][populate][0]=EindeutigeBezeichnung")
                .then(cnt => {
                    this.contents = cnt.data;
                })
                .catch(() => this.contents = false);
        },
        getFieldsLocations() {
            for (const location of ['physio', 'logo', 'ergo', 'fit']) {
                this.apiRequest('/locations?filters[Bereich][Titel][$eq]=' + location)
                    .then(cnt => {
                        this.fields[location] = cnt.data || null;
                    })
                    .catch(() => this.fields[location] = false);
            }
        },
        medauWrap(text) {
            return text.replace(/medau/ig, '<span class="medau">MEDAU</span>');
        }
    },
    mounted() {
        this.getMenuContent();
        this.getFieldsLocations();
    }
}
</script>