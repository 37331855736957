<template>
    <div class="loading-screen">
        <img :src="getImage('logo_bunt.svg')" alt="medau-logo" class="loading-logo">
        <LoadingSpinner/>
        <h2>Die Inhalte werden geladen.<br/>Bitte warten Sie einen Augenblick!</h2>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/elements/LoadingSpinner.vue";

export default {
    components: {LoadingSpinner}
}
</script>